import "./assets/css/style.css";

import ReactLogo from "./assets/images/loopycomet.svg";

function App() {
  return (
    <div>
      <header className="header" id="header">
        <nav className="nav container">
          <a href="#" className="nav__logo" style={{ fontSize: "30px" }}>
            LoopyComet
          </a>

          <div className="nav__menu" id="nav-menu">
            <ul className="nav__list">
              <li className="nav__item">
                <a href="#about" className="nav__link">
                  About us
                </a>
              </li>
              <li className="nav__item">
                <a href="#services" className="nav__link">
                  Contact Us
                </a>
              </li>
              <li className="nav__item">
                <a href="#contact" className="nav__link">
                  Support
                </a>
              </li>

              <i
                className="bx bx-toggle-left change-theme"
                id="theme-button"
              ></i>
            </ul>
          </div>

          <div className="nav__toggle" id="nav-toggle">
            <i className="bx bx-grid-alt"></i>
          </div>

          <a href="#" className="button button__header">
            Explore
          </a>
        </nav>
      </header>

      <main className="main">
        <section className="home section" id="home">
          <div className="home__container container grid">
            <div className="home__data">
              <h1 className="home__title">
                Get organized, <br /> Set Clear Goals
              </h1>
              <p className="home__description">
                Create your free account at any time and we will deliver the
                personalized shoping cart app for you.
              </p>

              <a href="#" className="button">
                Explore our products
              </a>
            </div>
            <img src={ReactLogo} alt="React Logo" />
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
